<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('finance_reports')" :is-filter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('finance_reports')" :is-filter="false">
                </HeaderMobile>
            </template>
            <div class="row data-form">
                <div class="col-12">
                    <ValidationObserver ref="form">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <ValidationProvider name="report" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('report')">
                                        <multi-selectbox :allow-empty="false" :multiple="false"
                                                         :validate-error="errors[0]" :options="computedReportOptions"
                                                         v-model="form.report"></multi-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6">
                                <ValidationProvider name="response_type" rules="required" v-slot="{valid, errors}"
                                                    v-if="showReportType">
                                    <b-form-group :label="$t('report_type')">
                                        <multi-selectbox :allow-empty="false" :multiple="false"
                                                         :validate-error="errors[0]" :options="responseTypeOptions"
                                                         v-model="form.response_type"></multi-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showPeriod">
                                        <ValidationProvider name="period_id" :rules="required.includes('period')?'required':''" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('period')">
                                                <payment-periods-selectbox
                                                    :multiple="isPeriodMultiple"
                                                    :semesters="semesters"
                                                    :validate-error="errors[0]"
                                                    v-model="form.period_id">
                                                </payment-periods-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showCurrency">
                                        <ValidationProvider name="currency" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('currency')">
                                                <multi-selectbox
                                                    :multiple="isCurrecyMultiple"
                                                    :options="computedCurrencies"
                                                    :validate-error="errors[0]"
                                                    v-model="form.currency">
                                                </multi-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showDate" >
                                        <ValidationProvider name="start_date" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('date')">
                                                <b-input type="date"
                                                         v-model="form.date">
                                                </b-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showDates" >
                                        <ValidationProvider name="start_date" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('start_date')">
                                                <b-input type="date"
                                                         v-model="form.start_date">
                                                </b-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showDates">
                                        <ValidationProvider name="end_date" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('end_date')">
                                                <b-input type="date"
                                                         v-model="form.end_date">
                                                </b-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showSemester">
                                        <ValidationProvider name="semester_id" :rules="required.includes('semester')?'required':''" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('semester')">
                                                <semesters-selectbox
                                                    :validate-error="errors[0]"
                                                    v-model="form.semester_id">
                                                </semesters-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showAcademicYear">
                                        <ValidationProvider name="academic_year" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('academic_year')">
                                                <academic-years-selectbox
                                                    :validate-error="errors[0]"
                                                    v-model="form.academic_year">
                                                </academic-years-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showSemesterNumber">
                                        <ValidationProvider name="semester" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('semester')">
                                                <parameter-selectbox
                                                    code="semester"
                                                    :validate-error="errors[0]"
                                                    v-model="form.semester">
                                                </parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showRegistrationType">
                                        <ValidationProvider name="registration_type" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('registration_type')">
                                                <parameter-selectbox
                                                    code="registration_types"
                                                    :multiple="true"
                                                    :validate-error="errors[0]"
                                                    v-model="form.registration_type">
                                                </parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showProgramLevel">
                                        <ValidationProvider name="program_level" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('program_level')">
                                                <parameter-selectbox
                                                    code="program_levels"
                                                    :validate-error="errors[0]"
                                                    v-model="form.program_level">
                                                </parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showFaculty">
                                        <ValidationProvider name="faculty_code" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('faculty_code')">
                                                <faculty-selectbox
                                                    :validate-error="errors[0]"
                                                    :multiple="isFacultyMultiple"
                                                    v-model="form.faculty_code">
                                                </faculty-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showOneRow">
                                        <ValidationProvider name="is_one_row" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('is_one_row')">
                                                <multi-selectbox :allow-empty="false" :multiple="false"
                                                                 :validate-error="errors[0]" :options="oneRowOptions"
                                                                 v-model="form.is_one_row"></multi-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showCustomerGroup">
                                        <ValidationProvider name="customer_group" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('customer_group')">
                                                <multi-selectbox :allow-empty="false" :multiple="false"
                                                                 :validate-error="errors[0]" :options="customerGroupOptions"
                                                                 v-model="form.customer_group"></multi-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showCurrencyRate">
                                        <ValidationProvider name="currency_rate" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('currency_rate')">
                                                <b-input type="number"
                                                         v-model="form.currency_rate">
                                                </b-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showNumbers">
                                        <ValidationProvider name="number" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('student_numbers')">
                                                <lined-textarea v-model="form.number"
                                                                :nowrap="false"
                                                                :disabled="false"
                                                                :styles="{ height: '15em', resize: 'both' }"
                                                                :validateError="errors[0]">
                                                </lined-textarea>
                                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                </div>
                            </div>

                            <div class="col-12">
                                <processing-button @click="send" label="get_report"
                                                   :processing="processing"></processing-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout"
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import {ValidationObserver, ValidationProvider} from "vee-validate";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import PaymentPeriodsSelectbox from "@/components/interactive-fields/PaymentPeriodsSelectbox";
import FinanceReportService from "@/services/FinanceReportService";
import ProcessingButton from "@/components/elements/ProcessingButton";
import LinedTextarea from "../../../components/elements/LinedTextarea";
import SemestersSelectbox from "../../../components/interactive-fields/SemestersSelectbox";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox.vue";

export default {
    components: {
        AcademicYearsSelectbox,
        SemestersSelectbox,
        LinedTextarea,
        ProcessingButton,
        PaymentPeriodsSelectbox,
        MultiSelectbox,
        AppLayout,
        ValidationProvider,
        ValidationObserver,
        Header,
        HeaderMobile
    },
    metaInfo() {
        return {
            title: this.$t('finance_reports')
        }
    },
    data() {
        return {
            showPeriod: false,
            required: [],
            showCurrency: false,
            isCurrecyMultiple: false,
            isFacultyMultiple: false,
            showDates: false,
            showDate: false,
            showSemester: false,
            showSemesterNumber: false,
            showRegistrationType: false,
            showNumbers: false,
            showOneRow: false,
            showReportType: true,
            showAcademicYear: false,
            showProgramLevel: false,
            showFaculty: false,
            form: {
                response_type: 'page'
            },
            reportOptions: [
                {value: '1', text: this.$t('number_of_students_report'), permission:'report_numberofstudents'},
                {value: '2', text: this.$t('collection_report'), permission: 'report_collection'},
                {value: '3', text: this.$t('cash_collection_report'), permission: 'report_cashcollection'},
                {value: '4', text: this.$t('pilotage_collection_report'), permission: 'paymentplan_pilotagereport'},
                {value: '5', text: this.$t('student_collection_report'), permission: 'report_studentcollection'},
                {value: '6', text: this.$t('education_credits_report'), permission: 'report_educationcredits'},
                {value: '7', text: this.$t('early_payment_report'), permission: 'report_earlypayment'},
                {value: '8', text: this.$t('graduate_full_scholarship_ratio_report'), permission: 'report_graduatefullscholarshipratio'},
                {value: '9', text: this.$t('summer_school_report'), permission: 'summerschool_paymentreport'},
                {value: '10', text: this.$t('collection_and_numbers_report'), permission: 'report_collectionandnumbers'},
                {value: '11', text: this.$t('scholarship_report'), permission: 'report_scholarship'},
                {value: '12', text: this.$t('creditpayments_report'), permission: 'report_creditpayments'},
                {value: '13', text: this.$t('scholarship_analysis_report'), permission: 'report_scholarshipanalysis'},
                {value: '14', text: this.$t('offset_balance_report'), permission: 'report_offsetbalance'},
                {value: '15', text: this.$t('course_registration_without_payment_report'), permission: 'report_courseregistrationwithoutpayment'},
                {value: '16', text: this.$t('monthly_collection_report'), permission: 'report_monthlycollection'},
                {value: '17', text: this.$t('number_of_students_and_collection_report'), permission: 'report_numberofstudentsandcollection'},
                {value: '18', text: this.$t('graduate_number_of_students_and_collection_report'), permission: 'report_graduatenumberofstudentsandcollection'},
                {value: '19', text: this.$t('detailed_monthly_collection_report'), permission: 'report_detailedmonthlycollection'},
                {value: '20', text: this.$t('number_of_students_and_collection_by_classes_report'), permission: 'report_numberofstudentsandcollectionbyclasses'},
                {value: '21', text: this.$t('yok_full_scholarship_control_report'), permission: 'report_scholarshipanalysis'},
                {value: '22', text: this.$t('osym_scholarship_analysis_report'), permission: 'report_registrationscholarshipanalysis'},
                {value: '23', text: this.$t('new_and_reenrollment_collection_report'), permission: 'report_newandreenrollmentcollection'},
                {value: '24', text: this.$t('scholarship_collection_report'), permission: 'report_scholarshipcollection'},
            ],
            responseTypeOptions: [
                {value: 'page', text: this.$t('page')},
                {value: 'excel', text: this.$t('excel')},
                //{value: 'pdf', text: 'PDF'},
            ],
            oneRowOptions: [
                {value: '1', text: this.$t('yes')},
                {value: '0', text: this.$t('no')},
            ],
            processing: false,
            reportName: "",
            semesters: null,
            currencies: [
                {value: null,  text: this.$t('select')},
                {value: 'TRY', text:'TRY'},
                {value: 'USD', text:'USD'},
                {value: 'EUR', text:'EUR'},
                {value: 'TL', text:'HEPSİ TRY'},
            ],
            showAllTL:false,
            isPeriodMultiple: true,
            showCustomerGroup: false,
            showCurrencyRate: false,
            customerGroupOptions: [
                {value: 'TR', text: 'TR'},
                {value: 'YU', text: 'YU'},
            ],
        }
    },
    created() {
        this.reportOptions = this.reportOptions.filter(item=>{
            return this.checkPermission(item.permission);
        });
    },
    methods: {
        async send() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                this.processing = true;
                this.reportName = this.getOptionText(this.reportOptions, this.form.report);
                let config = {params: this.form};
                let oldNumber=this.form.number;

                if(['11','21'].includes(this.form.report)){
                    config = {...this.form};
                    if(oldNumber){
                        config.number=config.number.split(/\n/);
                    }
                }
                else {
                    if(oldNumber){
                        config.params.number=config.params.number.split(/\n/);
                    }
                }
                let isArrayBuffer=false;
                if (this.form.response_type != 'page' && !['11','13','22'].includes(this.form.report)) {
                    config.responseType = 'arraybuffer';
                    isArrayBuffer=true;
                }

                if (this.form.report == '1' && this.checkPermission('report_numberofstudents')) {
                    FinanceReportService.numberOfStudents(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } else if (this.form.report == '2' && this.checkPermission('report_collection')) {
                    FinanceReportService.collection(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } else if (this.form.report == '3' && this.checkPermission('report_cashcollection')) {
                    FinanceReportService.cashCollection(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } else if (this.form.report == '4' && this.checkPermission('paymentplan_pilotagereport')) {
                    FinanceReportService.pilotage(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '5' && this.checkPermission('report_studentcollection')) {
                    FinanceReportService.studentCollection(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '6' && this.checkPermission('report_educationcredits')) {
                    FinanceReportService.educationCredits(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '7' && this.checkPermission('report_earlypayment')) {
                    FinanceReportService.earlyPayment(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '8') {
                    FinanceReportService.graduateFullScholarshipRatio(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '9') {
                    FinanceReportService.summerSchool(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '10') {
                    FinanceReportService.collectionAndNumbers(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '11') {
                    FinanceReportService.scholarship(config)
                    .then(response=>{
                        this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '12') {
                    FinanceReportService.creditPayments(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '13') {
                    FinanceReportService.scholarshipAnalysis(config)
                        .then(response=>{
                            this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.form, isArrayBuffer);
                        }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '22') {
                    FinanceReportService.registrationScholarshipAnalysis(config)
                        .then(response=>{
                            this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.form, isArrayBuffer);
                        }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '14') {
                    FinanceReportService.offsetBalance(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '15') {
                    FinanceReportService.courseRegistrationWithoutPayment(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '16') {
                    FinanceReportService.monthlyCollection(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '17') {
                    FinanceReportService.numberOfStudentsAndCollection(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '18') {
                    FinanceReportService.graduateNumberOfStudentsAndCollection(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '19') {
                    FinanceReportService.detailedMonthlyCollection(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '20') {
                    FinanceReportService.numberOfStudentsAndCollectionByClasses(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '21') {
                    FinanceReportService.yokFullScholarshipControl(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '23') {
                    FinanceReportService.newAndReenrollmentCollection(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '24') {
                    FinanceReportService.scholarshipCollection(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
                this.form.number=oldNumber;
            }
        },
        callback(response) {
            if (this.form.response_type == 'page') {
                this._openFile(response);
            }
            else if (this.form.response_type == 'excel') {
                this._downloadFile(response, this.reportName + '.xlsx');
            }
            else if (this.form.response_type == 'pdf') {
                this._downloadFile(response, this.reportName + '.pdf');
            }
        }
    },
    watch: {
        'form.report'(newVal, oldVal) {

            if (newVal != oldVal) {
                this.form = {response_type: 'page', report: newVal}
            }

            this.showDates = false;
            this.showDate = false;
            this.showPeriod = false;
            this.showCurrency = false;
            this.showReportType = true;
            this.semesters=null;
            this.showAllTL=false;
            this.showNumbers=false;
            this.isCurrecyMultiple = false;
            this.showAcademicYear = false;
            this.showProgramLevel = false;
            this.showFaculty = false;
            this.showOneRow = false;
            this.required = [];
            this.isPeriodMultiple = true;
            this.showCustomerGroup = false;
            this.isFacultyMultiple = false;
            this.showCurrencyRate = false;
            this.showSemesterNumber = false;
            this.showRegistrationType = false;

            if (newVal == '2' || newVal == '23') {
                this.showPeriod = true;
                this.semesters = [0,1,2,3,5,6];
                this.showCurrency = true;
                this.showSemester = true;
                this.required=['semester', 'period'];
            } else if (newVal == '3') {
                this.showDates = true;
                this.showPeriod = true;
                this.showCurrency = true;
                this.showAllTL=true;
            } else if (newVal == '4') {
            }
            else if (newVal == '5') {
                this.showPeriod = true;
                this.semesters = [0,1,2,3,5,6];
                this.showReportType = false;
                this.form.response_type = 'excel';
                this.showCurrency = true;
                this.showSemester = true;
                this.required=['semester', 'period'];
            }
            else if (newVal == '6') {
                this.showNumbers=true;
            }
            else if (newVal == '7') {
                this.showDates = true;
                this.showPeriod = true;
            }
            else if (newVal == '9') {
                this.showAcademicYear = true;
                this.showProgramLevel = true;
                this.showFaculty = true;
                this.isFacultyMultiple = true;
            }
            else if (newVal == '10') {
                this.semesters = [0,1,2,3,5,6];
                this.showDate = true;
                this.showPeriod = true;
            }
            else if (newVal == '11') {
                this.semesters = [0,1,2,3,5,6];
                this.showPeriod = true;
                this.showSemester = true;
                this.showFaculty = true;
                this.isFacultyMultiple = true;
                this.showOneRow = true;
                this.showReportType = false;
                this.showCurrencyRate = true;
                this.showNumbers = true;
                this.required = ['period'];
            }
            else if (newVal == '12') {
                this.semesters = [1,2,3];
                this.showPeriod = true;
                this.showFaculty = true;
                this.isFacultyMultiple = true;
                this.required = ['period'];
                this.isPeriodMultiple = false;
                this.showCustomerGroup = true;
            }
            else if (newVal == '13') {
                this.semesters = [0,1,2,3,5,6];
                this.showPeriod = true;
                this.showFaculty = true;
                this.isFacultyMultiple = true;
                this.required = ['period'];
                this.showCustomerGroup = true;
                this.isPeriodMultiple=false;
                this.showAcademicYear=true;
                this.showSemesterNumber=true;
                this.showRegistrationType=true;
            }
            else if (newVal == '15') {
                this.showReportType = false;
                this.showSemester = true;
                this.showDates = true;
                this.form.response_type = 'excel';
            }
            else if (newVal == '16') {
                this.showCurrency = true;
            }
            else if (newVal == '17') {
                this.showFaculty = true;
                this.isFacultyMultiple = true;
                this.showCurrency = true;
            }
            else if (newVal == '18') {
                this.showCurrency = true;
            }
            else if (newVal == '19') {
                this.showCurrency = true;
            }
            else if (newVal == '20') {
                this.showFaculty = true;
                this.isFacultyMultiple = true;
                this.showCurrency = true;
            }
            else if (newVal == '21') {
                this.showNumbers = true;
            }
            else if (newVal == '24') {
                this.semesters = [0,1,2,3,5,6];
                this.showFaculty = true;
                this.showPeriod = true;
                this.showCustomerGroup = true;
                this.showCurrency = true;
                this.isFacultyMultiple=true;
            }
        }
    },
    computed:{
        computedCurrencies(){
            return this.currencies.filter((item)=>{
                return item.value!='TL' || this.showAllTL;
            })
        },
        computedReportOptions(){
            return this.reportOptions.filter(item=>{
                return this.checkPermission(item.permission);
            });
        }
    }
};
</script>

