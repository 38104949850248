import FinanceApi from '@/services/FinanceApi';
import Api from '@/services/Index';

const numberOfStudents = async (config) => {
    return FinanceApi.get('/reports/number-of-students', config);
}

const collection = async (config) => {
    return FinanceApi.get('/reports/collection', config);
}

const newAndReenrollmentCollection = async (config) => {
    return FinanceApi.get('/reports/new-and-reenrollment-collection', config);
}

const scholarshipCollection = async (config) => {
    return FinanceApi.get('/reports/scholarship-collection', config);
}

const studentCollection = async (config) => {
    return FinanceApi.get('/reports/student-collection', config);
}

const cashCollection = async (config) => {
    return FinanceApi.get('/reports/cash-collection', config);
}

const pilotage = async (config={}) => {
    return FinanceApi.get('/payment/plans/pilotage/report', config);
}

const balance = async (config) => {
    return FinanceApi.get('/reports/balance', config);
}

const educationCredits = async (config) => {
    return FinanceApi.get('/reports/education-credits', config);
}

const earlyPayment = async (config) => {
    return FinanceApi.get('/reports/early-payment', config);
}

const graduateFullScholarshipRatio = async (config) => {
    return FinanceApi.get('/reports/graduate-full-scholarship-ratio', config);
}

const summerSchool = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/summer/school/payment/report', config);
}

const collectionAndNumbers = async (config) => {
    return FinanceApi.get('/reports/collection-and-numbers', config);
}

const scholarship = async (config) => {
    return FinanceApi.post('/reports/scholarship', config);
}

const scholarshipAnalysis = async (config) => {
    return FinanceApi.get('/reports/scholarship-analysis', config);
}

const registrationScholarshipAnalysis = async (config) => {
    return FinanceApi.get('/reports/registration-scholarship-analysis', config);
}

const creditPayments = async (config) => {
    return FinanceApi.get('/reports/credit-payments', config);
}

const offsetBalance = async (config) => {
    return FinanceApi.get('/reports/offset-balance', config);
}

const courseRegistrationWithoutPayment = async (config) => {
    return FinanceApi.get('/reports/course-registration-without-payment', config);
}

const monthlyCollection = async (config) => {
    return FinanceApi.get('/reports/monthly-collection', config);
}

const detailedMonthlyCollection = async (config) => {
    return FinanceApi.get('/reports/detailed-monthly-collection', config);
}

const numberOfStudentsAndCollection = async (config) => {
    return FinanceApi.get('/reports/number-of-students-and-collection', config);
}

const numberOfStudentsAndCollectionByClasses = async (config) => {
    return FinanceApi.get('/reports/number-of-students-and-collection-by-classes', config);
}

const graduateNumberOfStudentsAndCollection = async (config) => {
    return FinanceApi.get('/reports/graduate-number-of-students-and-collection', config);
}

const yokFullScholarshipControl = async (config) => {
    return FinanceApi.post('/reports/yok-full-scholarship-control', config);
}

export default {
    numberOfStudents, collection, cashCollection, pilotage, studentCollection, balance, educationCredits, earlyPayment, graduateFullScholarshipRatio, summerSchool,
    collectionAndNumbers, scholarship, creditPayments, scholarshipAnalysis, offsetBalance, courseRegistrationWithoutPayment, monthlyCollection,
    numberOfStudentsAndCollection, graduateNumberOfStudentsAndCollection, detailedMonthlyCollection, numberOfStudentsAndCollectionByClasses, yokFullScholarshipControl,
    registrationScholarshipAnalysis, newAndReenrollmentCollection, scholarshipCollection
}
